/**
 * Property Hero organism
 */

.o-propertyHero {
    $block: &;

    margin-inline: auto;
    max-width: 1600px;
    position: relative;

    @include breakpoint('md') {
        margin-bottom: gutter('xxl');
    }

    &__label {
        align-items: center;
        display: flex;
    }

    &__labelIcon {
        background-color: color('secondary');
        border-radius: border-radius('round');
        display: flex;
        height: 1rem;
        margin-right: gutter('xxs');
        width: 1rem;
    }

    &__imageContainer {
        position: relative;
    }

    &__video {
        height: 100%;
        inset: 0;
        object-fit: cover;
        object-position: center;
        pointer-events: none;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    &__imageLink {
        align-items: center;
        border-radius: 0;
        bottom: 0;
        cursor: pointer;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity speed() easing();
        width: 100%;
        z-index: 2;

        @include hover-focus() {
            opacity: 0.9;

            img {
                transform: scale(1.05);
            }
        }

        &.noHover {
            cursor: default;
            opacity: 1 !important;
        }

        @include breakpoint('md') {
            align-items: normal;
            justify-content: normal;
        }
    }

    &__video + &__imageLink {
        opacity: 0;
    }

    &__videoPauseButton {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.7);
        height: 5rem;
        width: 5rem;
        z-index: 3;
        border-radius: 100vw;
        color: #fff;
        left: 1.5rem;
        bottom: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint('md') {
            right: 3rem;
            bottom: 2rem;
            left: auto;
        }

        @include hover-focus() {
            --_bgHoverColor: rgba(0, 0, 0, 0.7);
            opacity: 0.9;
        }
    }

    &__imageLinkOverlay {
        align-items: center;
        background: rgba(0, 0, 0, 0.7);
        color: color('white');
        display: flex;
        font-size: font-size('base');
        justify-content: center;
        margin: auto;
        padding: 1.5rem 2rem;
        position: absolute;
        border-radius: 100vw;
        gap: 1rem;

        @include breakpoint('md') {
            top: auto;
            gap: 3rem;
            font-size: font-size('lead');
            padding: 2.25rem 2.25rem 2.25rem 5rem;
            bottom: gutter('lg');
            left: 0;
            right: 0;
            width: fit-content;
            margin-inline: auto;
        }
    }

    &__imageLinkOverlayText {
        display: none;

        @include breakpoint('md') {
            display: inline;
        }
    }

    &__imageLinkOverlayIcon {
        align-items: center;
        color: color('white');
        display: flex;
        justify-content: center;

        @include breakpoint('md') {
            border: solid 2px;
            border-radius: 100%;
            height: gutter('xxl');
            width: gutter('xxl');
        }
    }

    &__image {
        height: 100%;
        object-fit: cover;
        object-position: center;
        transform: scale(1);
        transition: transform 0.6s ease;
        width: 100%;

        @supports not (aspect-ratio: 16 / 9) {
            position: absolute;
            top: 0;
        }

        &.noHover {
            transform: none !important;
        }
    }

    &__labelsGrid {
        display: grid;
        gap: 4rem;
        grid-column: 1/3;
        grid-row: 1/-1;
        grid-template-columns: repeat(6, minmax(20px, 1fr));
        height: 100%;
        left: 0;
        margin: 0 auto;
        pointer-events: none;
        position: absolute;
        right: 0;
        z-index: 3;

        @include breakpoint('md') {
            grid-template-columns: repeat(12, minmax(20px, 1fr));
            padding: 0 gutter('xs');
        }

        @include breakpoint('md') {
            max-width: 120rem;
        }

        @include breakpoint('lg') {
            max-width: 172rem;
        }

        @include breakpoint('xl') {
            max-width: 176rem;
        }

        @include breakpoint('xxl') {
            max-width: 180rem;
        }
    }

    &__propertyLabels {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        left: 2rem;
        pointer-events: none;
        position: absolute;
        top: 2rem;

        &__label {
            background-color: rgba(color('white'), 0.8);
            border-radius: border-radius();
            color: color('black');
            font-size: font-size('label');
            max-width: 35ch;
            overflow: hidden;
            padding: gutter('xxs') gutter('xs');
            pointer-events: none;
            text-overflow: ellipsis;
            white-space: nowrap;

            &--liebhaver {
                background-color: color('liebhaver');
                border-radius: 0;
                color: color('liebhaver-gold');
            }
        }
    }
}

.o-propertyHeroImageGrid {
    $block: &;

    display: grid;
    gap: border-width();
    gap: 2px;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: calc((328 / 375) * 100vw);
    max-height: 577px;
    width: 100%;

    @include breakpoint('md') {
        height: calc((822 / 1600) * 100vw);
        max-height: 577px;
    }

    &.hasTwo {
        grid-template-columns: 1fr;
        grid-template-rows: 2fr 1fr;

        @include breakpoint('md') {
            grid-template-columns: 1.784fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
    }

    &.hasThree {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2fr 1fr;

        @include breakpoint('md') {
            grid-template-columns: 1.784fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
    }

    &__image {
        grid-column: 1;
        grid-row: 1;
        position: relative;

        #{$block}.hasTwo & {
            @include breakpoint('md') {
                grid-column: 1;
                grid-row: 1 / 3;
            }

            &:nth-of-type(2) {
                grid-row: 2;

                @include breakpoint('md') {
                    grid-column: 2;
                    grid-row: 1 / 3;
                }
            }
        }

        #{$block}.hasThree & {
            &:nth-of-type(1) {
                grid-column: 1 / span 2;

                @include breakpoint('md') {
                    grid-column: 1;
                    grid-row: 1 / span 2;
                }
            }

            &:nth-of-type(2) {
                grid-row: 2;

                @include breakpoint('md') {
                    grid-column: 2;
                    grid-row: 1;
                }
            }

            &:nth-of-type(3) {
                grid-column: 2;
                grid-row: 2;

                @include breakpoint('md') {
                    grid-column: 2;
                    grid-row: 2;
                }
            }
        }
    }
}
