/**
 * Video atom
 */

video {
    display: block;
    // Note! This fixes a bug in the Blink rendering engine.
    // Sometimes the browser will render a 1px black line at the bottom or right side of the video
    // Using a filter forces the video redering to use the GPU and fixes the issue
    // https://stackoverflow.com/questions/20771971/video-player-shows-black-border
    // Solution found by @el-muerto
    // https://codepen.io/el-muerto/full/WNVzVwO
    filter: grayscale(0);
    object-fit: cover;
    overflow: hidden;
    width: 100%;

    &:focus {
        outline: none;
    }

    &[data-aspect='16/9'] {
        aspect-ratio: 16/9;
    }
}

.a-video {
    @extend video;
}
