.m-noConsent {
    aspect-ratio: 16 / 9;
    background-color: color('green');
    color: color('white');
    display: grid;
    padding: gutter('md');
    place-content: center;
    place-items: center;
    text-align: center;
    text-wrap-style: balance;
}
