/**
 * Property Description organism
 */

.o-propertyDescription {
    $block: &;

    @include lora();

    grid-area: text;

    @include breakpoint('sm', 'down') {
        padding-top: gutter('md');
    }

    &__title {
        margin-bottom: gutter('sm');
    }

    &__overlayLinks {
        display: flex;
        flex-wrap: wrap;
        gap: gutter('xs') gutter('sm');
        margin-bottom: gutter('sm');
    }

    &__description {
        @include lora();

        .o-hiddenText__trigger {
            .luxurious & {
                color: color('liebhaver-gold');

                @include hover-focus() {
                    color: color('liebhaver-gold:hover');
                }
            }
        }
    }
}

.o-propertyDetailsGrid {
    $block: &;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        'anchors'
        'facts'
        'text';
    position: relative;

    @include breakpoint('md') {
        grid-template-columns: 46rem 4rem auto;
        grid-template-rows: auto 1fr;
        gap: gutter('md') 0;
        grid-template-areas:
            'facts . anchors'
            'facts . text';
    }

    @include breakpoint('lg') {
        grid-template-columns: 45rem 15rem auto;
    }

    .o-moduleLinks {
        grid-area: anchors;

        @include breakpoint('sm', 'down') {
            margin-inline: -4rem;
            width: 100vw;

            .o-moduleLinks__buttons {
                margin-inline: 0;
                padding-inline: 4rem;
                scroll-padding-inline: 4rem;
            }
        }
    }

    &__openHouseIcon {
        height: gutter('xxl');
        justify-content: center;
        padding: 0;
        width: gutter('xxl');
    }

    &__openHouseButton {
        align-items: center;
        background-color: color('paperYellow');
        color: color('black');
        display: flex;
        gap: 2rem;
        justify-content: space-between;
        padding-block: 2.25rem;
        padding-inline: 4rem 2.25rem;
        pointer-events: all;
        text-align: left;
        z-index: 2;

        &--deadlineExceeded {
            padding-inline: 4rem;
        }

        @include breakpoint('sm', 'down') {
            width: 100%;
        }

        @include breakpoint('md') {
            border-radius: 100vw;
            top: -22.5rem;
            left: 0;
            position: absolute;
        }

        [screen-reader] &:focus,
        [mouse] &:hover {
            #{$block}__openHouseIcon {
                background-color: color('secondary:hover');
                border-color: color('secondary:hover');
                color: color('white');
            }
        }
    }

    &__openHouseTextIntro {
        font-size: font-size('small');
    }

    &__openHouseTextDate {
        margin-top: gutter('xxs');

        @include breakpoint('md') {
            font-size: font-size('h3');
        }
    }

    &__info {
        background-color: color('green-dark');
        color: color('white');
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        justify-content: stretch;
        padding: gutter('xs');
    }

    &__infoContainer {
        grid-area: facts;
    }

    &__comingSoonContainer {
        align-items: center;
        background: rgba(255, 237, 219, 1);
        display: flex;
        gap: 1rem;
        padding: 3rem;
    }

    &__views {
        align-items: center;
        display: flex;
        flex: 0 0 auto;
        margin-bottom: -#{gutter('md')};

        @include breakpoint('md') {
            align-items: center;
            display: flex;
            flex: 0 0 auto;
            margin-top: gutter('sm');
        }
    }
}

.o-propertyDetailsGrid--comingSoon {
    gap: 4rem;
    grid-template-areas:
        'facts'
        'broker'
        'text';

    @include breakpoint('md') {
        gap: 4rem 0;
        grid-template-columns: 45rem 11rem auto;
        grid-template-areas:
            'facts . broker'
            'facts . text';
    }

    @include breakpoint('lg') {
        gap: 0;
        grid-template-columns: 45rem 11rem 36rem 6rem auto;
        grid-template-areas: 'facts . broker . text';
    }

    .o-employeeCard {
        grid-area: broker;
        max-width: none;
    }

    .o-propertyDescription {
        font-family: 'Poppins', sans-serif;

        .a-paragraph,
        .o-content,
        .o-content * {
            font-family: inherit;
            font-weight: 500;
        }
    }
}
