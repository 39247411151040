/**
 * Anchor atom
 */

a {
    color: inherit;
    text-decoration: none;

    &:focus {
        outline: none;
    }

    &:hover {
        text-decoration: inherit;
    }
}

.a-anchor {
    @extend a;

    color: inherit;
    position: relative;
    text-decoration: underline;
    text-decoration-color: currentColor;
    transition: color speed() easing();

    &:focus-visible {
        outline: solid 0.25rem color('primary');
        text-decoration: underline;
        text-decoration-color: currentColor;
    }

    @media (hover: hover) {
        &:hover {
            color: color('secondary');
            text-decoration: underline;
            text-decoration-color: currentColor;
        }
    }

    &--underline {
        text-decoration: underline;
        text-decoration-color: currentColor;
    }

    &--small {
        font-size: font-size('small');
    }

    &--white {
        color: color('white');

        [mouse] &:hover {
            color: color('black');
            text-decoration-color: currentColor;
        }
    }

    &--secondary {
        color: color('secondary');

        &:hover {
            @media (hover: hover) {
                color: color('secondary:hover');
            }
        }
    }

    &--luxurious {
        color: color('liebhaver-gold') !important;

        &:hover {
            @media (hover: hover) {
                color: color('liebhaver-gold:hover') !important;
            }
        }
    }

    &--block {
        display: block;
    }
}

.has-anchor {
    a {
        @extend .a-anchor;
    }
}
