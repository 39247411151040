/**
 * Property Gallery organism
 */

.o-propertyGallery {
    $block: &;

    --gutter: 0rem;

    @media (min-width: 900px) {
        --gutter: 4rem;
    }
    @media (orientation: portrait), (max-width: 1000px) {
        --gutter: 0rem;
    }

    border: none;
    position: fixed;
    inset: 0;
    padding: 0 var(--gutter);
    overflow: auto;
    scroll-padding-block: var(--gutter);
    overscroll-behavior: contain;
    margin: 0;
    max-height: 100vh;
    max-height: 100lvh;
    max-width: none;
    width: auto;

    &::before,
    &::after {
        content: '';
        background-color: rgba(255, 255, 255, 1);
        height: var(--gutter);
        left: 0;
        right: 0;
        position: sticky;
        display: block;
        z-index: 45;
    }
    &::before {
        top: 0;
    }
    &::after {
        bottom: 0;
    }

    &__grid {
        display: grid;
        grid-template-columns: auto 26rem 5rem;
        grid-template-areas: 'images buttons margin';
        gap: var(--gutter);

        @media (orientation: portrait), (max-width: 1000px) {
            grid-template-columns: auto 5rem 1rem !important;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
            background-color: #e4e4e4;
        }
    }

    &__galleryImages {
        display: grid;
        gap: 2px;
        list-style-type: none;
        margin: 0;
        padding: 0;
        scroll-padding-top: var(--gutter);
        scroll-padding-bottom: var(--gutter);
        grid-row: 1;
        grid-column: 1 / 2;

        // Use only on propertypages. Without this, it would also apply to Project Sale Pages
        .t-property & {
            @media (orientation: portrait), (max-width: 1000px) {
                margin-block-start: 7rem;
            }
        }

        @media (orientation: portrait), (max-width: 1000px) {
            grid-column: 1 / -1;
        }

        li {
            position: relative;
        }
    }

    &__imageDescription {
        position: absolute;
        background-color: color('white');
        border-radius: 5px;
        color: color('black');
        font-size: font-size('small');
        padding: 0.5rem 1rem;
        right: 2rem;
        top: 2rem;
        z-index: 1;

        @include breakpoint('md') {
            top: 4rem;
            right: 4rem;
        }
        @media (orientation: portrait), (max-width: 1000px) {
            right: 9rem;
            top: 2.5rem;
        }
    }

    &__galleryThumbnailImages {
        display: grid;
        gap: 2px;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: sticky;
        inset: var(--gutter) 0;
        height: calc(100vh - (var(--gutter) * 2));
        grid-column: 2 / 3;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        img {
            pointer-events: none;
        }

        button {
            display: block;
            width: 100%;
        }

        @media (orientation: portrait), (max-width: 1000px) {
            display: none;
        }
    }

    &__actions {
        align-self: start;
        display: grid;
        gap: 1rem;
        grid-column: -2 / -1;
        grid-template-rows: 5rem 5rem 5rem 5rem;
        grid-template-areas:
            'close'
            'fullscreen'
            'prev'
            'next';
        inset: var(--gutter) 0;
        position: sticky;

        @media (orientation: portrait), (max-width: 1000px) {
            align-items: center;
            grid-row: 1;
            justify-content: end;
            grid-column: 2/3;
            grid-area: buttons;
            inset: 1rem 0;
        }

        .m-button {
            align-items: center;
            border: none;
            border-radius: 100vh;
            display: flex;
            height: 5rem;
            justify-content: center;
            padding: 0;
            width: 5rem;
        }

        #{$block}__closeButton {
            grid-area: close;
        }

        #{$block}__fullscreenButton {
            grid-area: fullscreen;

            @media (orientation: portrait), (max-width: 1000px) {
                display: none;
            }
        }

        #{$block}__prevButton {
            grid-area: prev;
            @media (orientation: portrait), (max-width: 1000px) {
                display: none;
            }
        }

        #{$block}__nextButton {
            grid-area: next;

            @media (orientation: portrait), (max-width: 1000px) {
                display: none;
            }
        }
    }

    &__buttons {
        align-items: center;
        display: grid;
        gap: 2rem;
        grid-auto-flow: column;
        position: absolute;
        right: gutter('sm');
        top: gutter('sm');
        transition: top speed() easing();
        z-index: 10;

        @include breakpoint('sm', 'down') {
            @media (orientation: landscape) {
                right: gutter('xxs');
                top: gutter('xxs');
            }
        }

        @include breakpoint('md') {
            grid-auto-flow: row;

            > button:nth-of-type(1) {
                grid-row: 2;
            }

            > button:nth-of-type(2) {
                grid-row: 1;
            }
        }
    }

    &__danboligLogo {
        display: block;
        height: auto;
        grid-row: 1;
        grid-column: 1 / 2;
        left: 2rem;
        max-width: 100px;
        position: sticky;
        top: 4rem;
        width: 20rem;
        z-index: 1;

        @include breakpoint('md') {
            top: 6rem;
            left: 4rem;
        }
        @include breakpoint('lg') {
            top: 8rem;
        }

        @media (orientation: portrait), (max-width: 1000px) {
            top: 2rem;
            left: 2.5rem;
            height: 3rem;
        }

        .fillRed {
            fill: color('white');
            transition: fill speed() easing();
            .colorLogo & {
                fill: color('logoRed');
            }
        }

        .fillBlue {
            fill: color('white');
            transition: fill speed() easing();
            .colorLogo & {
                fill: color('logoBlue');
            }
        }
    }

    .thumb-button {
        margin: 0;
        padding: 0;
        display: block;
        /* outline: none; */
        border: none;
        outline-offset: -2px;
        border-radius: 0;
        position: relative;
        background-color: #e4e4e4;
        cursor: pointer;

        &:focus-visible {
            outline-style: solid;
            &::after {
                content: '';
                position: absolute;
                inset: 0;
                border: solid 2px #000;
            }
        }

        &.outline {
            outline: solid 2px #000;
            outline-offset: -2px;
        }

        .a-icon {
            position: absolute;
            bottom: 1rem;
            left: 1rem;
            color: color('white');
        }
    }

    &.fullscreen {
        --gutter: 0px;

        #{$block}__grid {
            grid-template-columns: auto 5rem 4rem;
            grid-template-areas: 'images buttons margin';
        }

        #{$block}__danboligLogo {
            inset: 2rem;

            @include breakpoint('md') {
                inset: 4rem;
            }
        }

        #{$block}__galleryImages {
            grid-column: 1/-1;
            grid-row: 1;
        }

        #{$block}__galleryThumbnailImages {
            display: none;
        }

        #{$block}__actions {
            grid-row: 1;
            grid-column: -2/-1;
            inset: 2rem;

            @include breakpoint('md') {
                inset: 4rem 0;
                grid-area: buttons;
            }
        }

        #{$block}__fullscreenButton {
            grid-area: close;
        }

        #{$block}__imageDescription {
            top: 2rem;
            right: 9rem;

            @include breakpoint('md') {
                top: 5rem;
                right: 13rem;
            }
        }
    }

    video {
        &::-internal-media-controls-overlay-cast-button,
        &::-internal-media-controls-playback-speed-button {
            display: none;
        }
    }
}
