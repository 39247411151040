/**
 * Property Preview organism
 */

.o-propertyPreview {
    $block: &;

    background-color: color('white');
    border-radius: border-radius('large');
    box-shadow: box-shadow('normal');
    color: color('black');
    overflow: hidden;
    position: relative;
    transition: speed() easing();
    transition-property: box-shadow;

    &--placeholder {
        height: 41rem;
        pointer-events: none;

        @include breakpoint('md') {
            height: 39.375rem;
        }
    }

    [screen-reader] &:not(.isSold):focus-within,
    [mouse] &:not(.isSold):hover,
    &:not(.isSold).isActive {
        box-shadow: box-shadow('normal:hover');
    }

    &__multiple {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: gutter('xxs') gutter('xs');
        position: relative;
        z-index: 10;
    }

    &__multipleArrows {
        align-items: center;
        display: flex;
    }

    &__multipleArrow {
        display: flex;
        margin: 0 gutter('xxs');
        transition: color speed() easing();

        [screen-reader] &:focus-within,
        [mouse] &:hover {
            color: color('secondary');
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__top {
        overflow: hidden;
        position: relative;
    }

    &__slider {
        position: relative;
    }

    &__indicators {
        bottom: gutter('xs');
        display: flex;
        left: gutter('xs');
        position: absolute;
        user-select: none;
        z-index: 1;

        #{$block}.hasOpenHouse & {
            bottom: #{gutter('xs') + gutter()};
        }
    }

    &__indicator {
        background-color: color('white');
        border-radius: border-radius('round');
        height: gutter('xxs');
        transition: background-color speed() easing();
        width: gutter('xxs');

        @include breakpoint('sm', 'down') {
            display: none;
        }

        &:not(:last-child) {
            margin-right: gutter('xxs');
        }

        &.isActive {
            background-color: color('secondary');
        }
    }

    &__slides {
        @include aspect-ratio(345, 180);

        display: flex;
    }

    &__slide {
        bottom: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: speed('slow') easing();
        transition-property: transform;
        width: 100%;

        &--next {
            &-enter-active,
            &-leave-active {
                transform: translateX(0);
            }

            &-enter {
                transform: translateX(100%);
            }

            &-leave-to {
                transform: translateX(-100%);
            }
        }

        &--prev {
            &-enter-active,
            &-leave-active {
                transform: translateX(0);
            }

            &-enter {
                transform: translateX(-100%);
            }

            &-leave-to {
                transform: translateX(100%);
            }
        }
    }

    &__image {
        height: 100%;
        left: 0;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__controls {
        bottom: gutter('xs');
        display: flex;
        height: gutter('sm');
        opacity: 0;
        position: absolute;
        right: gutter('xs');
        transition: opacity speed() easing();
        z-index: 1;

        [screen-reader] #{$block}:focus-within &,
        [mouse] #{$block}:hover &,
        #{$block}.isActive & {
            opacity: 1;
        }

        #{$block}.hasOpenHouse & {
            bottom: #{gutter('xs') + gutter()};
        }
    }

    &__control {
        color: color('white');
        transition: color speed() easing();

        &:first-child {
            margin-right: gutter('xs');
        }

        [screen-reader] &:focus,
        [mouse] &:hover {
            color: color('secondary');
        }
    }

    &__externalBroker {
        bottom: gutter('xs');
        opacity: 1;
        position: absolute;
        right: gutter('xs');
        transition: opacity speed() easing();

        // [screen-reader] #{$block}:focus-within &,
        // [mouse] #{$block}:hover &,
        // #{$block}.isActive & {
        //     opacity: 0;
        // }

        #{$block}.hasOpenHouse & {
            bottom: #{gutter('xs') + gutter()};
        }
    }

    &__gradient {
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 48.96%,
            rgba(0, 0, 0, 0.5) 100%
        );
        bottom: 0;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__teaser {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        left: gutter('xs');
        position: absolute;
        right: gutter('xs');
        top: gutter('xs');
    }

    &__tag {
        background-color: rgba(color('white'), 0.8);
        border-radius: border-radius();
        color: color('black');
        max-width: 35ch;
        overflow: hidden;
        padding: #{gutter('xxs') / 2.66666} gutter('xxs');
        text-overflow: ellipsis;
        white-space: nowrap;
        z-index: 1;

        &--liebhaver {
            background-color: color('liebhaver');
            border-radius: 0;
            color: color('liebhaver-gold');
        }
    }

    &__openHouse {
        align-items: center;
        background-color: color('paperYellow');
        bottom: 0;
        color: color('black');
        display: flex;
        height: gutter();
        left: 0;
        padding: gutter('xxs');
        position: absolute;
        right: 0;
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        height: 19rem;
        justify-content: space-between;
        padding: gutter('xs');
    }

    &__text {
        display: flex;
        justify-content: space-between;
    }

    &__address {
        font-weight: font-weight('medium');
        line-height: 1.2;
        margin: 0;
    }

    &__addressElement {
        display: block;

        #{$block}__address.isMultiline & {
            display: inline;
        }
    }

    &__link {
        display: block;

        &::after {
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }

        [screen-reader] &:focus {
            text-decoration: underline;
            text-decoration-color: color('secondary');
        }
    }

    &__label {
        align-items: center;
        display: flex;
        line-height: 1.3;
        // margin-left: gutter('xxs');

        &::before {
            background-color: color('blue');
            border-radius: 100%;
            content: '';
            display: block;
            height: 8px;
            margin-right: 4px;
            width: 8px;
        }

        &--underSale::before {
            background-color: color('orange');
        }

        &--luxurious::before {
            background-color: color('liebhaver-gold');
        }
    }

    &__facts {
        align-items: center;
        display: flex;
        gap: gutter('xxs');
        max-width: calc(
            100% - 5rem
        ); //5rem is the width of the o-propertyPreview__icon
        min-height: 3rem; //This will match the height of any .energyLabel
    }

    &__fact {
        position: static;
    }

    &__icon {
        bottom: 0;
        display: flex;
        pointer-events: none;
        position: absolute;
        right: 0;
    }

    &__favorite {
        font-family: font-family('primary');
        font-weight: font-weight('medium');
        padding: gutter('xs');
        pointer-events: all;
        transition: color speed() easing();

        [screen-reader] &:focus,
        [mouse] &:hover {
            color: color('secondary');
        }

        &__text {
            color: color('black');
            flex: none;
            font-size: 9px;
            left: 0;
            margin-block: 0;
            margin-inline: auto;
            opacity: 0;
            padding: 0;
            position: absolute;
            right: 0;
            top: 50%;
            text-align: center;
            transform: translateY(-50%);

            &.animateIn {
                animation: animateIn 0.2s linear forwards;
            }
            &.animateOut {
                animation: animateOut 0.2s linear forwards;
            }
        }

        svg {
            display: block;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover svg .cls-3 {
                fill-rule: unset;
                fill: color('black');
            }
        }
    }

    &.isSold {
        box-shadow: none;

        #{$block}__bottom {
            color: color('gray-dark');
        }

        #{$block}__tag:not(.o-propertyPreview__tag--liebhaver) {
            background-color: color('logoRed');
            color: color('white');
        }

        #{$block}__image {
            opacity: 0.5;
        }

        #{$block}__label::before {
            background-color: color('logoRed');
        }
    }
}
