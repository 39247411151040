/**
 * Sass imports
 */

@import 'vue-good-table/dist/vue-good-table.css';

// variables
@import 'functions';
@import 'variables';
@import 'mixins';

// general
@import 'fonts';
@import 'animations';
@import 'normalize';

// structure
@import 'vendors/vendors';
@import 'utilities/utilities';
@import 'atoms/atoms';
@import 'molecules/molecules';
@import 'organisms/organisms';
@import 'templates/templates';
@import 'pages/pages';

// @import '/node_modules/swiper/swiper.min.css';

// app setup
#app {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
    width: 100%;
}

:root {
    --liebhaver: #{color('liebhaver')};
    --liebhaverGold: #{color('liebhaver-gold')};
    --liebhaverGoldDark: #{color('liebhaver-gold:hover')};
    --secondary: #{color('secondary')};
    --secondaryDark: #{color('secondary:hover')};
    --white: #{color('white')};
    --black: #{color('black')};
    --whiteDark: #{color('white:hover')};

    --swiper-theme-color: var(--secondary);
}

.luxurious {
    --swiper-theme-color: var(--liebhaverGold);

    .m-button--custom {
        --button-bg-clr: var(--liebhaverGold);
        --button-bg-active-clr: var(--liebhaverGoldDark);
        --button-txt-clr: var(--black);
    }
}

.m-button--luxurious {
    --button-bg-clr: var(--liebhaverGold);
    --button-bg-active-clr: var(--liebhaverGoldDark);
    --button-txt-clr: var(--black);
}
