/**
 * Municipality About organism
 */

.o-municipalityAbout {
    $block: &;

    &__title {
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            margin-bottom: gutter('xs');
        }
    }

    &__description {
        font-size: font-size();
        line-height: line-height();
        margin-bottom: gutter();
        padding-block-start: gutter('sm');

        @include lora();

        @include breakpoint('md') {
            margin-bottom: 0;
        }

        .o-hiddenText__wrapper {
            > *:first-child,
            > *:first-of-type {
                margin-top: 0;
            }
        }
    }

    &__data {
        @include breakpoint('md') {
            margin-bottom: 0;
            padding-left: gutter('xl');
        }
    }

    &__dataTable--second {
        @include breakpoint('md') {
            margin-bottom: gutter('sm');
            margin-top: gutter('xxxl');
        }
    }

    &__dataSource {
        text-align: end;
    }

    &__dataColumn {
        margin-bottom: gutter('xs');

        @include breakpoint('md') {
            margin-bottom: 0;
        }

        @include breakpoint('md', 'down') {
            td:last-child {
                padding-left: gutter('xxs');
                white-space: nowrap;
            }
        }
    }
}
