/**
 * Press Photos organism
 */

.o-pressPhotos {
    --image-ratio: 328 / 204;
    --lines-of-text: 3;

    display: grid;
    gap: gutter('md');
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    &__text {
        max-width: calc(180rem / 2 - 4rem);
        h2 {
            text-wrap-style: balance;
        }
        h2 + p {
            margin-top: gutter('xs');
        }
    }

    &__list {
        display: grid;
        gap: gutter('md');
        // don't change Min to min, this will break SCSS compilation
        // also, it does not matter since CSS is case-insensitive
        grid-template-columns: repeat(auto-fill, minmax(Min(100%, 300px), 1fr));

        &Item {
            background: color('white');
            border-radius: border-radius('large');
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-shadow: box-shadow('normal');
            overflow: hidden;
            transition: box-shadow 0.25s ease-in-out;
            height: 100%;

            &:focus-visible {
                outline: solid border-width('base') color('blue');
                box-shadow: box-shadow('normal:hover');
            }

            @media (hover: hover) {
                &:hover {
                    box-shadow: box-shadow('normal:hover');
                }
            }

            &__imageContainer {
                aspect-ratio: var(--image-ratio, 16/9);
                background: color('gray-light');
                flex: none;
                pointer-events: none; // To allow for tracking in the anchor element set up in eventBus.ts
                position: relative;
                overflow: hidden;
                width: 100%;

                img {
                    object-fit: cover;
                    width: 100%;
                    display: block;
                    aspect-ratio: inherit;
                    object-position: 50% 50%;
                    position: absolute;
                    inset: 0;
                }

                &[data-imagetype='svg'] {
                    img {
                        height: 60%;
                        margin: auto;
                        object-fit: contain;
                        width: 60%;
                    }
                }
            }

            &__infoContainer {
                padding: gutter('sm');
                gap: gutter('xs');

                display: flex;
                flex-direction: column;
                flex: 1 1 100%;
                grid-template-columns: 1fr;
                justify-content: space-between;
                pointer-events: none; // To allow for tracking in the anchor element set up in eventBus.ts

                .a-lead {
                    display: -webkit-box;
                    overflow: hidden;
                    text-wrap-style: balance;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: var(--lines-of-text, 3);
                    line-clamp: var(--lines-of-text, 3);
                }

                .a-label {
                    display: flex;
                    align-items: center;

                    svg {
                        display: block;
                        margin-right: gutter('xxs');
                        margin-left: rem(-3);
                    }
                }
            }
        }
    }
}
