/**
 * Media molecule
 */

.m-media {
    $block: &;

    display: flex;
    overflow: hidden;
    position: relative;

    &__image {
        display: flex;
    }

    &__video {
        position: relative;

        #{$block}.hasAutoplay & {
            @include breakpoint('md', 'down') {
                display: none;
            }
        }
    }

    &__button {
        background-color: rgba(color('primary'), 0);
        border: 0;
        border-radius: 0;
        bottom: 0;
        color: transparent;
        display: block;
        left: 0;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: background-color speed() easing();
        width: 100%;

        [screen-reader] #{$block}:focus &,
        [mouse] #{$block}:hover & {
            background-color: rgba(color('primary'), 0.1);
            color: transparent;
        }

        [touch] #{$block}.isPlaying & {
            opacity: 0;
        }
    }

    &__icon {
        flex: 0 0 auto;
        height: gutter('xxl') !important;
        pointer-events: none;
        transition: opacity speed() easing();
        width: gutter('xxl') !important;

        #{$block}.isPlaying & {
            opacity: 0;
        }

        #{$block}:hover & {
            background-color: color('secondary:hover');
            opacity: 1 !important;
        }
    }

    &__progress {
        appearance: none;
        background-color: color('black');
        border: 0;
        bottom: 0;
        color: color('secondary');
        height: 0.5rem;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        transition: opacity speed() easing();
        width: auto;

        #{$block}:hover & {
            opacity: 1;
        }

        &::-webkit-progress-value {
            background-color: color('secondary');
            transition: width 0.2s linear;
        }

        &::-moz-progress-bar {
            background-color: color('secondary');
        }
    }

    &.hasVideo {
        aspect-ratio: 16/9;
    }

    &.hasYouTubeVideo {
        align-items: center;
        aspect-ratio: 16/9;
        justify-content: center;

        iframe {
            aspect-ratio: inherit;
            display: block;
            height: 100%;
            width: 100%;
        }
    }
}
