.a-section--findBuyerResult {
    @include breakpoint('sm', 'down') {
        overflow: hidden;
    }
}

.o-findBuyerResult {
    position: relative;
    margin-bottom: 90px;

    @include breakpoint('md', 'down') {
        margin-bottom: 0;
    }

    /**
    * HERO
    **/
    &__images {
        left: 50%;
        position: sticky;
        top: 0;
        width: 0;
        z-index: -1;
    }
    &__image {
        border-radius: border-radius('round');
        display: block;
        height: auto;
        left: 50%;
        max-width: 200px;
        position: absolute;
        top: 50%;
        width: 11.5vw;

        &:nth-child(1) {
            left: -52vw;
            top: 56.75rem;
            width: 18.2vw;
            max-width: 310px;

            @include breakpoint('xxl') {
                left: -104rem;
            }
            @include breakpoint('md', 'down') {
                display: none;
            }
            @include breakpoint('sm', 'down') {
                display: block;
            }
        }
        &:nth-child(2) {
            top: 13.375rem;
            left: -88.5rem;
        }
        &:nth-child(3) {
            top: 4.625rem;
            left: -22rem;
        }
        &:nth-child(4) {
            top: 68rem;
            left: 35.5rem;
            width: 18.2vw;
            max-width: 310px;
        }
        &:nth-child(5) {
            top: -3rem;
            left: 43.5rem;
            width: 18.2vw;
            max-width: 310px;
        }
        &:nth-child(6) {
            top: 41rem;
            left: 9.5rem;
        }
        &:nth-child(7) {
            top: 55rem;
            left: 39vw;

            @include breakpoint('xxl') {
                left: 78rem;
            }
        }

        @include breakpoint('md', 'down') {
            &:nth-child(4) {
                display: none;
            }
        }

        @include breakpoint('sm', 'down') {
            width: 125px;

            &:nth-child(1) {
                left: calc(0px - 12vw);
                top: 30px;
                width: 200px;
            }
            &:nth-child(2) {
                top: 0px;
                left: -52vw;
            }
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
                display: none;
            }
        }
    }
    &__image-below {
        display: flex;
        justify-content: flex-end;

        .o-findBuyerResult__image--last {
            display: block;
            border-radius: border-radius('round');
            height: auto;
            max-width: 200px;
            transform: translate(63px, 40px);
            width: 11.5vw;

            &:nth-child(2) {
                display: none;
            }
        }

        @include breakpoint('sm', 'down') {
            position: relative;
            display: flex;
            justify-content: left;
            padding-top: 65px;

            .o-findBuyerResult__image--last {
                width: 200px;
                transform: none;

                &:nth-child(2) {
                    position: absolute;
                    right: -45px;
                    top: 20px;
                    width: 125px;
                    display: block;
                }
            }
        }
    }
    &__heroText {
        padding-top: 255px;
        margin-bottom: gutter('md');

        @include breakpoint('md', 'down') {
            padding-top: 165px;
        }
        @include breakpoint('sm', 'down') {
            padding-top: 236px;
        }
    }
    &__count {
        font-size: 12.5rem;
        line-height: 1;
        display: block;
    }

    /**
    * FORM
    **/
    &__form {
        &__intro {
            margin-bottom: gutter('md');
        }
        &__headline {
            margin-bottom: gutter('xs');
        }
    }
    &__input {
        margin-bottom: gutter('xs');
    }
    &__input--select {
        z-index: 9;
        margin-bottom: gutter('md');
    }
    &__sliderHeadline {
        margin-bottom: gutter('lg');
        font-size: font-size('label');
    }
    &__slider {
        margin-bottom: gutter('md');
    }
    .m-rangeSlider__slider {
        margin-bottom: gutter('xxs');
    }
    .vue-slider-dot-tooltip-top {
        visibility: visible;
        top: -3px;

        .vue-slider-dot-tooltip-inner {
            transform: none;
            opacity: 1;

            .vue-slider-dot-tooltip-text {
                width: auto;
                height: auto;
                font-size: 20px;
                color: black;
                display: block;
                transform: none;
            }
        }
    }
    .m-rangeSlider__value {
        font-size: font-size('lead');
        line-height: line-height('lead');
    }
}
